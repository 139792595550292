import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BackEndService } from './back-end.service';
import { UserService } from './user.service';
import { SharedService, KeysPipe, SpeedkmphPipe, LabelOptionsService } from './shared.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [KeysPipe, SpeedkmphPipe],
  providers: [BackEndService, UserService, SharedService, LabelOptionsService],
  exports: [KeysPipe, SpeedkmphPipe]
})
export class ServiceModule { }
