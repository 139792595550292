import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private members:any = [];

  selectedMember: Object;

  constructor() { }

  // -------------------------- TODO: Move below code to other service ----------------------------------
  public setMembers(userData:object[]){
    this.members = userData
    //INFO: use below if data need not be refreshed
    sessionStorage.setItem('members',JSON.stringify(userData));
  }

  public getMembers(){
    return JSON.parse(sessionStorage.getItem('members'));
  }

  public clearUsers(){
    sessionStorage.removeItem('members');
  }

  //--------------------------------- END ----------------------------------------------

  getUserDetails(){

    return sessionStorage.getItem('userDetails');
  }

  setUserDetails(userDetails){
    sessionStorage.setItem('userDetails',JSON.stringify(userDetails));
    this.setAuthToken(userDetails['authToken']);
  }

  getAuthToken(){

    return JSON.parse(sessionStorage.getItem('authToken'));
  }

  setAuthToken(token : any){
    sessionStorage.setItem('authToken',JSON.stringify(token));
  }

  setOmanniAuthToken(token : any){
    sessionStorage.setItem('oamnniToken',JSON.stringify(token));
  }

  getOmmaniAuthToken(){
    return JSON.parse(sessionStorage.getItem('oamnniToken'));
  }

  clearUser(){

    sessionStorage.removeItem('userDetails');
    this.clearToken();
  }

  clearToken(){

    sessionStorage.removeItem('authToken');
  }

  clearDetails(){
    this.clearToken();
    this.clearUser();
  }

  logout(){

      this.clearToken();
      this.clearUser();
  }
}
