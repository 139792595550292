import { Injectable } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import { NativeDateAdapter, DateAdapter } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  getStartTime(date){
    
    let startTime = new Date(date).setHours(0);
    startTime = new Date(startTime).setMinutes(0);
    startTime = new Date(startTime).setSeconds(0);
    startTime = new Date(startTime).setMilliseconds(0);
    startTime = new Date(startTime).getTime();

    return startTime;
  }
  
  getTime(date){
   return new Date(date).getTime();
  }

  getEndTime(date){
    
    let endTime = new Date(date).setHours(23);
    endTime = new Date(endTime).setMinutes(59);
    endTime = new Date(endTime).setSeconds(59);
    endTime = new Date(endTime).setMilliseconds(999);
    endTime = new Date(endTime).getTime();

    return endTime;
  }

  getCurrentStartTime(){
    
    let currentTime = new Date().setHours(0);
    currentTime = new Date(currentTime).setMinutes(0);
    currentTime = new Date(currentTime).setSeconds(0);
    currentTime = new Date(currentTime).setMilliseconds(0);
    currentTime = new Date(currentTime).getTime();

    return currentTime;
  }

  getCurrentEndTime(){
    
    let currentTime = new Date().setHours(23);
    currentTime = new Date(currentTime).setMinutes(59);
    currentTime = new Date(currentTime).setSeconds(59);
    currentTime = new Date(currentTime).setMilliseconds(999);
    currentTime = new Date(currentTime).getTime();

    return currentTime;
  }
  //INFO: Takes milliseconds and returns time in 12 hours format
  getFormattedTime(timeInMilli){
    
    let newTime = new Date(timeInMilli)
    let hours = newTime.getHours();
    let minutes = newTime.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? Number('0'+minutes) : minutes;
    let calcTime = hours + ':' + minutes + ' ' + ampm;
    return calcTime;
  }

  private _listners = new Subject<any>();

    listen(): Observable<any> {
       return this._listners.asObservable();
    }

    filter(filterBy: string) {
       this._listners.next(filterBy);
    }

}


@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {
  transform(value, args:string[]) : any {
    /*let keys = [];
    for (var enumMember in value) {
      if (!isNaN(parseInt(enumMember, 10))) {
        keys.push({key: enumMember, value: value[enumMember]});
        // Uncomment if you want log
      } 
    }
    return keys;*/
    if(args[value] != undefined)
        return args[value];
    else
        return "";    
  }
}

@Pipe({name: 'speedkmph'})
export class SpeedkmphPipe implements PipeTransform {
  transform(value) : any {
    
    if (!isNaN(parseInt(value, 10))) {
      return value * 3.6;
    }
  }
}

@Injectable()
export class LabelOptionsService {

  constructor() { }

  get startLabel(){

    return {
      color: '#000000',
      fontFamily: '',
      fontSize: '14px',
      fontWeight: 'bold',
      text: 'A',
    }
  }

  get endLabel(){
    
        return {
          color: '#000000',
          fontFamily: '',
          fontSize: '14px',
          fontWeight: 'bold',
          text: 'B',
        }
  }

  get stopLabel(){
    
        return {
          color: '#000000',
          fontFamily: '',
          fontSize: '14px',
          fontWeight: 'bold',
          text: 'S',
        }
  }

}

export const MY_DATE_FORMATS = {
  parse: {
      dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
      // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};


export class MyDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
      if (displayFormat == "input") {
          let day = date.getDate();
          let month = date.getMonth() + 1;
          let year = date.getFullYear();
          return this._to2digit(day) + '/' + this._to2digit(month) + '/' + year;
      } else {
          return date.toDateString();
      }
  }

  private _to2digit(n: number) {
      return ('00' + n).slice(-2);
  } 
}