import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup,FormControl,Validators } from '@angular/forms';
import {Router} from '@angular/router';

import { BackEndService } from '../services/back-end.service';
import { environment } from '../../environments/environment';
import { UserService } from '../services/user.service';
import { ActivatedRoute } from "@angular/router";

import { NgxSpinnerService } from 'ngx-spinner';

import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm : FormGroup;
  invalidLogin: boolean;
  invalidMessage: string;

  @Output() onFilter: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router, private backEndService : BackEndService,
    private route: ActivatedRoute, private userService: UserService,
    private spinner: NgxSpinnerService, private SharedService : SharedService) { 

    this.route.queryParams.subscribe(params => {
      if (params['cid'] && params['pwd']) {
        try {
          let body = {
            "emailId": atob(params['cid']),
            "password": atob(params['pwd'])
          };
          this.loginUser(body);
        } catch (error) {
          console.log("error found");
        }
      }
    });
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.loginForm = new FormGroup({
      email: new FormControl('',Validators.required),
      password: new FormControl('',Validators.required)
    });
  }

  onSubmit(){

    if (this.loginForm.valid) {
      
      let body = {
        "emailId": this.loginForm.value.email,
        "password": this.loginForm.value.password
      };
      
      this.loginUser(body);
    }
  }

  loginUser(body) {

    this.spinner.show();
    this.backEndService.postLogin(`${environment.login}`, body)
      .subscribe(resp => {

        // this.spinner.hide();
        this.SharedService.filter('Register click');
        if (resp['status'] == 'SUCCESS') {
          if (resp.hasOwnProperty('payLoad')) {
            console.log(resp);
            this.userService.setUserDetails(resp['payLoad']);
            this.router.navigate(['user']);
          } else {
            throw new Error("Auth token missing");
          }
          this.invalidLogin = false;
        } else if (resp['status'] == 'FAILURE') {
          this.invalidLogin = true;
          this.invalidMessage = resp['err'].errorMessage;
          this.spinner.hide();
        }
      }, err => {
        this.spinner.hide();
      });
  }
}
