import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders , HttpErrorResponse} from '@angular/common/http';
import {RequestOptions, Request, RequestMethod} from '@angular/http';

import { environment } from '../../environments/environment';

import { Observable, throwError } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';
import { UserService } from './user.service';

import 'rxjs/add/observable/forkJoin';

@Injectable({
  providedIn: 'root'
})
export class BackEndService {

  authToken : any;
  authAdminId : number;

  constructor(private http: HttpClient, private userService:UserService) {
    
  }

  private getHeaders(): Object {

    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json',
                                                     'authToken': this.userService.getAuthToken() }) };
    return httpOptions;
  }

    post(url:string, body, options?): Observable<any>{

      let httpOptions = this.getHeaders();

      if(options != undefined){
        httpOptions = options
      }

      return this.http.post(`${environment.SERVER_URL}${environment.bussines}` + url, body, httpOptions)
        .pipe(map(resp => resp),
        catchError(this.handleError));
    }  

    postSendLink(url:string, body, options?): Observable<any>{

      let httpOptions = {
        headers: new HttpHeaders().set('authToken', this.userService.getAuthToken())
        .set('Content-Type', 'application/json') 
      };

      if(options != undefined){
        httpOptions = options
      }

      return this.http.post( `${environment.SERVER_URL}` + url, body, httpOptions)
        .pipe(map(resp => resp),
        catchError(this.handleError));  
    }

    adminPutSendLink(url:string, body, options?): Observable<any>{
      
    
      var userData = JSON.parse(this.userService.getUserDetails());
      this.authAdminId = userData.adminId;

      let httpOptions = {
        headers: new HttpHeaders().set('authToken', this.userService.getAuthToken())
        .set('Content-Type', 'application/json')
        .set('adminId', this.authAdminId.toString()) 
      };

      if(options != undefined){
        httpOptions = options
      }

      return this.http.put( `${environment.SERVER_URL}` + url, body, httpOptions)
        .pipe(map(resp => resp),
        catchError(this.handleError));  
    }

    

    putSendLink(url:string, body, options?): Observable<any>{

      let httpOptions = {
        headers: new HttpHeaders().set('authToken', this.userService.getAuthToken())
        .set('Content-Type', 'application/json') 
      };

      if(options != undefined){
        httpOptions = options
      }

      return this.http.put( `${environment.SERVER_URL}` + url, body, httpOptions)
        .pipe(map(resp => resp),
        catchError(this.handleError));  
    }

    forJoinGetSTRPolyLine(urlParamsList:Array<string>): Observable<any>{

      //let newUrl : string = `${environment.snapToRoadUrl}` + urlparams + `${environment.apiKey}`;
      var snapToRoadMultiRequest : Array<Observable<any>> = [];
      urlParamsList.forEach(element => {
        var request = this.http.get(`${environment.snapToRoadUrl}` + element +'&interpolate=true'+ `${environment.apiKey}`)
        snapToRoadMultiRequest.push(request);
      });
      
      return Observable.forkJoin(snapToRoadMultiRequest);  
    }

    getSendLink(url:string, param?){
  
      let httpOptions = {
        headers: new HttpHeaders().set('authToken', this.userService.getAuthToken())
        .set('Content-Type', 'application/json') 
      };

      if(param){
        httpOptions['params'] = param;
      }

      return this.http.get( `${environment.SERVER_URL}` + url, httpOptions)
      .pipe(map(resp => resp),
      catchError(this.handleError));  
    }



    deleteSendLink(url:string, param?){

      let httpOptions = {
        headers: new HttpHeaders().set('authToken', this.userService.getAuthToken())
        .set('Content-Type', 'application/json') 
      };

      if(param){
        httpOptions['params'] = param;
      }

      return this.http.delete(`${environment.SERVER_URL}` + url, httpOptions)
      .pipe(map(resp => resp),
      catchError(this.handleError));  
    }

    postLogin(url:string, body): Observable<any>{
      
      return this.http.post( `${environment.SERVER_URL}${environment.bussines}` + url, body )
        .pipe(map(resp => resp),
        catchError(this.handleError));
    }

    adminPostLogin(url:string, body): Observable<any>{
      let httpOptions = {
        headers: new HttpHeaders().set('Content-Type', 'application/json') ,
        withCredentials: true
      };
      return this.http.post( `${environment.SERVER_URL}` + url, body, httpOptions )
        .pipe(map(resp => {
          return resp;
        }),
        catchError(this.handleError));
    }

    postDelivryPoints(url:string, body, options?): Observable<any>{

      return this.http.post(`${environment.httpUrl}${environment.deliveryPtPort}` + url, body)
        .pipe(map(resp => resp),
        catchError(this.handleError));  
    }


    getshareCode(groupId){
      
      let httpOptions = this.getHeaders();
      return new Promise((resolve, reject)=>{

      let url : string = `${environment.SERVER_URL}` + '/get/group-invite-code?groupId=' + groupId;
      this.http.get(url, httpOptions)
        .subscribe((resp)=>{
          resolve(resp);
        })
      });
    }

    omanicommPostBackEnd(url, body, options?){

      let httpOptions = {
        headers: new HttpHeaders().set('Content-Type', 'application/json') 
      };

      if(options != undefined){
        httpOptions = options
      }

      return this.http.post( `${environment.ommaniCommProBase}` + url, body, httpOptions)
        .pipe(map(resp => resp),
        catchError(this.handleError)); 
    }

    omanicommGetBackEnd(url:string, param?){
  
      let httpOptions = {
        headers: new HttpHeaders().set('authToken', this.userService.getAuthToken())
        .set('Content-Type', 'application/json') 
      };

      if(param){
        httpOptions['params'] = param;
      }

      return this.http.get( `${environment.ommaniCommProBase}` + url, httpOptions)
      .pipe(map(resp => resp),
      catchError(this.handleError));  
    }

    getMethodWithoutAuth(url){
      return this.http.get(`${environment.ommaniCommProBase}` + url)
      .pipe(map(resp => resp),
      catchError(this.handleError)); 
    }

    getMethodWithOmanniAuth(url){

      let httpOptions = {
        headers: new HttpHeaders().set('Authorization', 'JWT ' + this.userService.getOmmaniAuthToken())
        .set("responseType" , "blob")
      };

      return this.http.get( url , httpOptions)
      .pipe(map(resp => resp),
      catchError(this.handleError));  
    }

    

    getAddress(lat : number, lng : number):Promise<any>{

      return new Promise((resolve, reject)=>{

        let url : string = `${environment.googleBaseUrl}` +"latlng="+lat+","+lng + "&key=" + `${environment.MAP_API_KEY}`;
        this.http.get(url)
        .subscribe((resp)=>{

          // resolve(resp)
          // INFO: Below logic to handle OVER_QUERY_LIMIT error
          if(resp['status'] == "OVER_QUERY_LIMIT"){
            // setTimeout(()=>{
            //   this.getAddress(lat, lng);
            // },500)
          }else{
            resolve(resp)
          }

        })
      })
    }

    getAddressHere(lat : number, lng : number):Promise<any>{

      return new Promise((resolve, reject)=>{

        let url : string = `https://reverse.geocoder.api.here.com/6.2/reversegeocode.json?prox=`+lat+`%2C`+lng+
        `&mode=retrieveAddresses&maxresults=1&&app_id=iTvlm7GKY9yvXzRGEEFx&app_code=7WlgKoQYoAdJl2U3PurywQ`;
        this.http.get(url)
        .subscribe((resp)=>{

          // resolve(resp)
          // INFO: Below logic to handle OVER_QUERY_LIMIT error
          if(resp['status'] == "OVER_QUERY_LIMIT"){
            // setTimeout(()=>{
            //   this.getAddress(lat, lng);
            // },500)
          }else{
            resolve(resp)
          }

        })
      })
    }





    getSTRPolyline(urlparams:string):Promise<any>{
      
      return new Promise((resolve, reject)=>{

        let newUrl : string = `${environment.snapToRoadUrl}` + urlparams + `${environment.apiKey}`;
        this.http.get(newUrl)
        .subscribe((resp)=>{
          resolve(resp)
        })
      })
    }


  private handleError(error: HttpErrorResponse) {

    // this._toastr.error('Please try again later', 'Something went wrong', { timeOut: 3000, closeButton: true });
    // return Observable.throw(error);

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.log('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }

}
