// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  httpUrl : 'http://139.59.46.238:',
  deliveryPtPort:'4999',
  SERVER_URL : 'https://server.locate365.in', //http://139.59.46.238:9000 //https://app.locate365.in //http://10.4.21.197:9000
  bussines : '/business',
  login : '/login',
  snapToRoadUrl : 'https://roads.googleapis.com/v1/snapToRoads?path=',
  googleBaseUrl : 'https://maps.googleapis.com/maps/api/geocode/json?',
  apiKey : '&key=AIzaSyAhBskN2Nm9cbEihwCEE4CWE2R-8iGyCTA',
  MAP_API_KEY : 'AIzaSyDi5O_ZKHS7Tfh5Z8bbPDVe4AzD1BdWhUA',
  ommaniCommProBase : 'https://app.locate365.in/api/v1/' 
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
