import { Component, OnInit } from '@angular/core';

import { BackEndService } from '../services/back-end.service';

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent implements OnInit {

  constructor(private backEndService: BackEndService) { }

  private rattingCount : number;
  public review : String;

  ngOnInit() {
  }

  getData(event){
    console.log(event);
    this.rattingCount = event;
  }

  tabulateAnswers(){
    console.log(this.rattingCount);
    console.log(this.review);
  }

}
